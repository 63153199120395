<template>
    <div class="sign-up-wrap">
        <md-stepper ref="stepper" @completed="confirm">
            <md-step :md-editable="!hasCreateAccount">
                <VendorSignupAccount ref="VendorSignupAccount" />
            </md-step>
            <md-step :md-editable="!hasCreateMerchant">
                <VendorSignupStripe v-if="showStripe" ref="VendorSignupStripe" @stripe-input-exist="hanldeVendorSignupStripeExist" />
            </md-step>
            <md-step :md-editable="!hasCreatePayment">
                <div v-if="showStripeCard">
                    <p class="md-subheading" style="text-align: center;">Will be used to process app fees for cash and check transactions</p>
                    <VendorSignupStripeCard ref="VendorSignupStripeCard" />
                </div>
            </md-step>
            <md-step>
                <LocationVendorSetting v-if="showLocationSetting" :isNew="true" ref="LocationVendorSetting" />
            </md-step>
        </md-stepper>
    </div>
</template>

<script>
import VendorSignupAccount from "@/components/Vendor/VendorSignupAccount";
import VendorSignupStripe from "@/components/Vendor/Stripe/VendorSignupStripe";
import VendorSignupStripeCard from "@/components/Vendor/Stripe/VendorSignupStripeCard";
import LocationVendorSetting from "@/components/Location/LocationVendorSetting";
import { mapGetters } from "vuex";
export default {
    components: {
        VendorSignupAccount,
        VendorSignupStripe,
        VendorSignupStripeCard,
        LocationVendorSetting
    },
    data() {
        return {
            record: {},
            currentStep: 0,
            hasCreateAccount: false,
            hasCreateMerchant: false,
            hasCreatePayment: false,
            showStripe: false,
            showStripeCard: false,
            showLocationSetting: false,
            customValidate: []
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        })
    },
    methods: {
        async confirm() {
            let instance = this.$refs["LocationVendorSetting"];
            await instance.save();
            this.$store.commit("setMessage", "Signup success");
            this.$router.push("/");
        },
        hanldeVendorSignupStripeExist() {
            this.$refs.stepper.$el.querySelector(".md-step:nth-child(2) .md-step-actions .md-primary").click();
        }
    },
    created() {},
    mounted() {
        // test
        // console.log("111111111111111");
        // this.showStripe = true;
        // this.hasCreateAccount = true;
        // test end

        this.customValidate.push(async activeStepNumber => {
            let instance = this.$refs["VendorSignupAccount"];
            let status = await instance.customValidate();
            if (status) {
                await instance.save();
                this.showStripe = true;
                this.hasCreateAccount = true;
            } else {
                this.$store.commit("setMessage", "Please complete fields");
            }
            return status;
        });

        this.customValidate.push(async activeStepNumber => {
            this.currentStep = activeStepNumber;
            this.showStripeCard = true;
            this.hasCreateMerchant = true;
            return true;
        });

        this.customValidate.push(async activeStepNumber => {
            let status = false;
            const { id: paymentMethodId } = await this.$refs["VendorSignupStripeCard"].getPaymentMethodId();

            if (paymentMethodId) {
                await this.$store.dispatch("crud/post", {
                    api: "users/vendor/signup",
                    data: {
                        paymentMethodId
                    }
                });
                status = true;
                this.hasCreatePayment = true;
                this.showLocationSetting = true;
            } else {
                this.$store.commit("setMessage", "Please complete fields");
            }
            return status;
        });

        this.customValidate.push(async activeStepNumber => {
            return true;
        });

        const that = this;

        this.$nextTick(() => {
            let stepLock = false;
            this.$refs["stepper"].setActiveStep = async function(stepData) {
                if (stepLock) {
                    return;
                }

                const nextStepNumber = this.getStepIndex(stepData.id);

                if (this.activeStepNumber === nextStepNumber) {
                    return;
                }
                if (this.activeStepNumber > nextStepNumber && !stepData.editable) {
                    return;
                }
                stepLock = true;

                try {
                    let r = await that.customValidate[this.activeStepNumber](nextStepNumber);
                    if (!r) {
                        stepLock = false;
                        return;
                    }
                } catch (error) {
                    stepLock = false;
                    throw error;
                }

                stepLock = false;
                this.activeStep = stepData.id;
                this.activeStepNumber = nextStepNumber;
                // this.calculatePosition();
                this.$emit("change", this.activeStepNumber);
            };
        });

        window.onbeforeunload = async function() {
            await window.localStorage.removeItem("authUser");
            window.onbeforeunload = null;
        };
    },
    async beforeDestroy() {
        window.localStorage.removeItem("authUser");
        window.onbeforeunload = null;
    }
};
</script>
<style lang="less" scoped>
.sign-up-wrap /deep/ .md-step-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.sign-up-wrap /deep/ .md-step:nth-child(2) .md-step-actions {
    display: none;
    // height: 0;
    // width: 0;
    // overflow: hidden;
    // opacity: 0;
}

.sign-up-wrap /deep/ .md-step-content .md-button:nth-of-type(2) {
    display: none;
}

.sign-up-wrap /deep/ .md-whiteframe-1dp {
    box-shadow: none;
}
</style>
