<template>
    <div class="vendor-signup bg">
        <md-card>
            <md-card-content>
                <md-layout>
                    <h2>Grow Your Business</h2>
                    <router-link tag="md-button" to="/account/login">Login</router-link>
                </md-layout>
                <h2>Create Your Account Today!</h2>
                <div class="edit-fields">
                    <template v-for="(f, ix) in vendorSignupFields">
                        <div v-if="f.data === 'checkVendorTerms'" class="edit-field">
                            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" style="margin-bottom:0">
                                <md-checkbox :data-vv-name="f.data" :data-vv-rules="f.validate" v-model="record[f.data]" v-validate> </md-checkbox>
                                <span style="margin-right:3px">I have read and agree to the</span>
                                <a @click.prevent="$refs['vendorTermsModal'].open()" href="/vendor-terms-of-service">Vendors Terms and Service Agreement</a>
                                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                            </md-input-container>
                        </div>

                        <div v-else-if="f.data === 'checkUserTerms'" class="edit-field">
                            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }">
                                <md-checkbox :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" v-model="record[f.data]" v-validate></md-checkbox>
                                <span style="margin-right:3px">I have read and agree to the</span>
                                <a @click.prevent="$refs['userTermsModal'].open()" href="/terms-of-service">Users Terms and Service Agreement</a>
                                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                            </md-input-container>
                        </div>

                        <edit-field v-else :f="f" :model="record" ref="edit-field"> </edit-field>
                    </template>
                </div>
            </md-card-content>
        </md-card>

        <TermsOfServiceModal ref="userTermsModal"></TermsOfServiceModal>
        <VendorTermsOfServiceModal ref="vendorTermsModal"></VendorTermsOfServiceModal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import EditField from "@/components/EditField";
import Edit from "@/components/Edit";
import Submit from "@/components/Submit";
import { vendorSignupFields } from "@/config";
import TermsOfServiceModal from "@/components/modal/TermsOfServiceModal";
import VendorTermsOfServiceModal from "@/components/Vendor/VendorTermsOfServiceModal";

const demo = {
    role: "vendor",
    name: "8ySnKc9V",
    email: "8ySnKc9V@qq.com",
    password: "8ySnKc9V",
    checkVendorTerms: true,
    checkUserTerms: true
};

export default {
    components: {
        Edit,
        Submit,
        EditField,
        TermsOfServiceModal,
        VendorTermsOfServiceModal
    },
    computed: {
        ...mapGetters({
            setModel: "setModel",
            getModel: "getModel"
        }),
        vendorSignupFields() {
            return vendorSignupFields.filter(field => !field.when || field.when == "edit");
        }
    },
    data() {
        return {
            record: {
                // ...demo
            }
        };
    },

    created() {
        this.setDefault();
    },
    methods: {
        ...mapMutations({
            setUser: "user/setUser"
        }),
        async setDefault() {
            this.vendorSignupFields.forEach(field => {
                if (field.default != void 0) {
                    let currentValue = this.getModel(this.record, field.data);
                    this.setModel(this.record, field.data, currentValue || JSON.parse(JSON.stringify(field.default)));
                }
            });
        },

        async save() {
            const result = await this.$store.dispatch(`crud/post`, {
                api: "users/vendor",
                data: { ...this.record }
            });

            result.user.token = result.token;
            this.$store.commit("user/setVendorSignupToken", result.token);
            // this.setUser(result.user);
            return result;
        },

        getRecord() {
            console.log(this.record);
        },

        async customValidate() {
            const customValidateResult = await this.$validator.validateAll();
            const validateResults = await Promise.all(
                (this.$refs["edit-field"] || []).map(async comp => {
                    return await comp.$validator.validateAll();
                })
            );
            if (!customValidateResult || validateResults.findIndex(r => !r) > -1) {
                return false;
            }
            return true;
        }
    }
};
</script>

<style lang="less" scoped>
.vendor-signup {
    .md-card {
        max-width: 750px;
        padding: 10px;
        margin: 0 auto;

        .md-card-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            color: #666;
        }

        .md-card-actions {
            justify-content: flex-end;
        }
    }

    //fix checkbox
    .md-input-container {
        line-height: 41px;

        .md-error {
            line-height: 10px;
        }
    }
}
</style>
