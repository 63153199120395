<template>
    <div class="stripe-card-edit">
        <stripe-new-card ref="stripeNewCard" @confirm="confirm" @cancel="cancel" :signup="true"></stripe-new-card>
    </div>
</template>
<script>
import StripeNewCard from "@/components/Stripe/StripeNewCard";
export default {
    components: {
        StripeNewCard
    },
    methods: {
        async getPaymentMethodId() {
            const token = await this.$refs["stripeNewCard"].confirm();

            return token;
        },
        confirm() {},
        cancel() {}
    }
};
</script>
<style lang="less" scoped>
.stripe-card-edit {
    /deep/ .stripe-new-card {
        max-width: 1000px;
        margin: auto;

        .md-card-header {
            display: none;
        }
    }
    /deep/ .md-card .md-card-actions {
        display: none;
    }
}
</style>
