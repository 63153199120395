<template>
    <div class="terms-of-service-modal">
        <md-dialog class="terms-of-service-modal-dialog" ref="dialog">
            <md-dialog-content>
                <terms-of-service></terms-of-service>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
import TermsOfService from "@/components/TermsOfService";
export default {
    components: {
        TermsOfService
    },
    methods: {
        open() {
            this.$refs["dialog"].open();
        },
        close() {
            this.$refs["dialog"].close();
        }
    }
};
</script>
<style lang="less">
.terms-of-service-modal-dialog {
    .md-dialog-content {
        padding: 0;
    }
    .md-card {
        margin: 0;
    }
}
</style>
