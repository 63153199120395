<template>
    <div class="VendorSignupTilled">
        <md-card>
            <md-card-content>
                <md-list ref="vendorForm">
                    <!-- General -->
                    <div ref="general">
                        <p class="md-title">General</p>

                        <!-- <md-list-expand> -->
                        <template v-for="(f, ix) in generalFields">
                            <edit-field :f="f" :model="generalRecord" ref="edit-field"></edit-field>
                        </template>
                        <!-- </md-list-expand> -->
                    </div>

                    <!-- Address -->
                    <div ref="businessAddress">
                        <p class="md-title">Business Address</p>

                        <!-- <md-list-expand ref="businessAddress"> -->
                        <template v-for="(f, ix) in addressFields">
                            <edit-field :f="f" :model="businessAddressRecord" ref="edit-field"></edit-field>
                        </template>
                        <!-- </md-list-expand> -->
                    </div>

                    <!-- Business Owners -->
                    <div ref="businessOwners">
                        <p class="md-title">Business Owners</p>

                        <!-- <md-list-expand ref="businessOwners"> -->
                        <template v-for="(f, ix) in businessOwnerFields">
                            <span class="md-caption" v-if="f.type == 'caption'">{{ f.heading }}</span>
                            <span class="md-subheading" v-else-if="f.type == 'subheading'">{{ f.heading }}</span>
                            <div v-if="f.type === 'checkbox'" class="edit-field">
                                <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" style="align-items: center;">
                                    <md-checkbox :data-vv-name="f.data" :data-vv-rules="f.validate" v-model="businessOwnersRecord[f.data]" v-validate> </md-checkbox>
                                    <div>
                                        <span style="margin-right:3px">{{ f.heading }} </span>

                                        <md-icon>help</md-icon>
                                        <md-tooltip md-direction="right">{{ f.desc }}</md-tooltip>
                                    </div>

                                    <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
                                </md-input-container>
                            </div>

                            <edit-field v-else :f="f" :model="businessOwnersRecord" ref="edit-field"> </edit-field>
                        </template>
                        <!-- </md-list-expand> -->
                    </div>

                    <!-- Bank Account -->
                    <div ref="bankAccount">
                        <p class="md-title">Bank Account</p>

                        <!-- <md-list-expand> -->
                        <template v-for="(f, ix) in bankAccountFields">
                            <edit-field :f="f" :model="bankAccountRecord" ref="edit-field"></edit-field>
                        </template>
                        <!-- </md-list-expand> -->
                    </div>

                    <!-- Review -->
                    <div ref="review">
                        <p class="md-title">Review</p>

                        <!-- <md-list-expand> -->
                        <div style="display:flex;flex-direction:column;" class="edit-field" v-if="validation_errors.length">
                            <span class="md-headline" style="text-align: center;">Please verify your information before submitting.</span>
                            <span class="md-body-2" style="color: red;" v-for="item in validation_errors">{{ item }}</span>
                        </div>

                        <md-table v-once>
                            <md-table-header>
                                <md-table-row>
                                    <md-table-head>Payment Method Type</md-table-head>
                                    <md-table-head md-numeric>Description</md-table-head>
                                    <md-table-head md-numeric>Currency</md-table-head>
                                </md-table-row>
                            </md-table-header>

                            <md-table-body>
                                <md-table-row v-for="(row, index) in product_codes" :key="index">
                                    <md-table-cell>{{ row.payment_method_type }}</md-table-cell>
                                    <md-table-cell>{{ row.description }}</md-table-cell>
                                    <md-table-cell>{{ row.currency }}</md-table-cell>
                                </md-table-row>
                            </md-table-body>
                        </md-table>
                        <div class="edit-field">
                            <md-input-container :class="{ 'md-input-invalid': errors.has('accept_terms_and_conditions') }" style="align-items: center;">
                                <md-checkbox :data-vv-name="'accept_terms_and_conditions'" :data-vv-rules="'agree|required'" v-model="accept_terms_and_conditions" v-validate> </md-checkbox>
                                <span style="margin-right:3px">I agree to the following:</span>
                                <span class="md-error" v-show="errors.has('accept_terms_and_conditions')">{{ errors.first("accept_terms_and_conditions") }}</span>
                            </md-input-container>
                            <p v-for="item in terms_and_conditions_links">
                                <a :href="item.link" target="_blank">{{ item.name }}</a>
                            </p>
                        </div>
                        <!-- </md-list-expand> -->
                    </div>
                </md-list>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { states, country } from "@/config";
import EditField from "@/components/EditField";
import AddressAutoFill from "@/components/Address/AddressAutoFillClass";
const demoParams = {
    generalRecord: {
        name: "demo202302242150",
        legal_name: "Legal Demo 001",
        type: "CHARITY",
        tax_identification_number: "986541123",
        phone: "4049332407",
        company_email: "demo202302242150@gm.com",
        locale: "en_US",
        region: "United States",
        average_transaction_amount: 123,
        yearly_volume_range: "MEDIUM",
        category: "ART",
        statement_descriptor: "staments",
        currency: "US Dollar"
    },
    businessAddressRecord: {
        country: "US",
        street: "115 E 57th St",
        city: "New York",
        state: "NY",
        zip: "10022"
    },
    businessOwnersRecord: {
        phone: "4049332407",
        ssn: "986541123",

        street: "1155 Brickell Bay Dr",
        city: "Miami",
        state: "FL",
        zip: "33131",
        country: "US",
        address: {
            zip: "60602",
            city: "Chicago",
            state: "IL",
            street: "118",
            country: "US"
        },
        job_title: "CTO",
        last_name: "Matthews",
        first_name: "Christopher",
        is_applicant: true,
        date_of_birth: "1993-02-09",
        is_control_prong: true,
        percentage_shareholding: "25"
    },
    bankAccountRecord: {
        account_number: "4111111111111111",
        routing_number: "041215032"
    },
    accept_terms_and_conditions: true
};

const States = Object.keys(states)
    .sort((a, b) => (a > b ? 1 : -1))
    .map(key => ({ value: states[key], text: key }));

const BusinessTypeOptoins = [
    { value: "CHARITY", text: "Charity" },
    { value: "CIC", text: "Community Interest Company" },
    { value: "CORP", text: "Corporation" },
    { value: "GOV", text: "Public Sector/Governmental" },
    { value: "LLC", text: "Limited Liability Company" },
    { value: "LLP", text: "Limited Liability Partnership" },
    { value: "LTD", text: "Limited" },
    { value: "NPCORP", text: "Non-Profit" },
    { value: "PARTNERSHIP", text: "Partnership" },
    { value: "PLC", text: "Public Limited Company" },
    { value: "SOLEPROP", text: "Sole Proprietorship/Sole Trader" },
    { value: "TRUST", text: "Trust" }
];

const LocaleOptions = [
    { value: "en_US", text: "United States" },
    { value: "en_CA", text: "Canadian English" },
    { value: "fr_CA", text: "Canadian French" },
    { value: "en_GB", text: "Europe" }
];

const YearlyVolumeOptions = [
    { value: "LOW", text: "0-50k" },
    { value: "MEDIUM", text: "50-100k" },
    { value: "HIGH", text: "100-250k" },
    { value: "VERY_HIGH", text: "250k+" }
];

const CategoryOptions = [
    { value: "ACCT", text: "Accounting" },
    { value: "ART", text: "Artist Supply and Craft Stores" },
    { value: "BEAUTY", text: "Barber & Beauty Shop" },
    { value: "CATERING", text: "Catering" },
    { value: "CHARITY", text: "Charity" },
    { value: "CLEANING", text: "Cleaning Services" },
    { value: "CONSULTANT", text: "Consultant" },
    { value: "CONTRACTOR", text: "Trade Contractor" },
    { value: "DENTIST", text: "Dentistry" },
    { value: "EDU", text: "Schools & Education" },
    { value: "FOOD", text: "Food/Grocery" },
    { value: "LANDSCAPING", text: "Landscaping" },
    { value: "LEGAL", text: "Legal Services" },
    { value: "MEDICAL_PRACT", text: "Medical Practitioner" },
    { value: "MEDICAL_SERV", text: "Health Services" },
    { value: "MEMBERSHIP", text: "Membership Org." },
    { value: "MISC_FOOD_STORES", text: "Misc. Food Stores" },
    { value: "MISC_MERCH", text: "Misc General Merchandise" },
    { value: "MISC_SERV", text: "Services" },
    { value: "MUSIC", text: "Music/Entertainment" },
    { value: "PC", text: "Computer Services" },
    { value: "PHOTO_FILM", text: "Photo/FILM" },
    { value: "PROF_SERV", text: "Professional Services" },
    { value: "REAL_ESTATE", text: "Real Estate" },
    { value: "RECREATION", text: "Recreation Services" },
    { value: "REPAIR", text: "Repair Services" },
    { value: "RESTO", text: "Restaurant/Bar" },
    { value: "RETAIL", text: "Direct Marketing Retail (MOTO)" },
    { value: "TAXI", text: "Taxi/Limo" },
    { value: "VET", text: "Veterinary" },
    { value: "WEB_DEV", text: "Web Design" },
    { value: "WEB_HOSTING", text: "Web Hosting" }
];

const GeneralFields = [
    { heading: "Business Name *", data: "name", type: "text", validate: "required" },
    { heading: "Legal Business Name *", data: "legal_name", type: "text", validate: "required" },
    { heading: "Business Type *", data: "type", type: "select", options: BusinessTypeOptoins, validate: "required" },
    { heading: "Tax ID Number *", placeholder: "9 digit Federal employer #", data: "tax_identification_number", type: "number", validate: "required" },
    { heading: "Company Phone *", data: "phone", type: "number", validate: "required" },
    { heading: "Company Email *", data: "company_email", type: "text", validate: "required|email" },
    { heading: "Company Website", placeholder: "http://", data: "website", type: "text", validate: "" },
    { heading: "Region", data: "region", type: "readonly", validate: "" },
    { heading: "Locale *", data: "locale", type: "select", options: LocaleOptions, validate: "required" },
    { heading: "Currency", data: "currency", type: "readonly", validate: "" },
    // 输入的数值要*100
    { heading: "Average Transaction Amount * ", data: "average_transaction_amount", type: "number", validate: "required" },
    { heading: "Yearly Transaction Volume *", data: "yearly_volume_range", type: "select", options: YearlyVolumeOptions, validate: "required" },
    { heading: "Category *", data: "category", type: "select", options: CategoryOptions, validate: "required" },
    { heading: "What name do you want to appear on customer credit card statements? *", data: "statement_descriptor", type: "text", validate: "required" }
];

const AddressFields = [
    { heading: "Address *", data: "street", type: "text", validate: "required" },
    { heading: "Street 2", data: "street2", type: "text" },
    { heading: "City *", data: "city", type: "text", validate: "required" },
    { heading: "State *", data: "state", type: "select", options: States, validate: "required" },
    { heading: "Zip *", data: "zip", type: "number", validate: "required" },
    { heading: "Country", data: "country", type: "select", options: [{ text: "United States", value: "US" }] }
];

// principals:[]
const BusinessOwnerFields = [
    { heading: "First Name *", data: "first_name", type: "text", validate: "required" },
    { heading: "Last Name *", data: "last_name", type: "text", validate: "required" },
    { heading: "Phone *", data: "phone", type: "number", validate: "required" },
    { heading: "Job Title *", data: "job_title", type: "text", validate: "required" },
    { heading: "Percentage Owned * (%)", placeholder: "1 to 100%", data: "percentage_shareholding", type: "number", validate: "required" },
    { heading: "Social Security Number *", data: "ssn", type: "number", validate: "required" },
    { heading: "Date of Birth *", data: "date_of_birth", type: "date", validate: "required" },
    { heading: "Owner's Residential Address", type: "subheading" },
    { heading: "Street *", data: "street", type: "text", validate: "required" },
    { heading: "Street 2", data: "street2", type: "text" },
    { heading: "City *", data: "city", type: "text", validate: "required" },
    { heading: "State *", data: "state", type: "select", options: States, validate: "required" },
    { heading: "Zip *", data: "zip", type: "number", validate: "required" },
    { heading: "Country", data: "country", type: "select", options: [{ text: "United States", value: "US" }] },
    {
        heading:
            "** Merchants applying for payment processing services are required to indicate 1 business representative (i.e. directors/trustees) as an applicant and 1 as a control prong. The applicant and control prong can be the same business owner.",
        type: "caption"
    },
    {
        heading: "Applicant",
        desc: "A business representative, UBO/director/trustee, who is completing & submitting the application on behalf of a legal entity.",
        data: "is_applicant",
        type: "checkbox"
    },
    {
        heading: "Control Prong",
        desc:
            "A single individual with significant responsibility to control, manage or direct a legal entity. An executive officer or senior manager such as CFO, CEO, President, Vice-President, and Treasurer meets this definition.",
        data: "is_control_prong",
        type: "checkbox"
    }
];

const BankAccountFields = [
    { heading: "Account Number *", data: "account_number", type: "number", validate: "required" },
    { heading: "Routing Number *", placeholder: "9 digit routing number", data: "routing_number", type: "number", validate: "required" }
];

const fieldsMap = {
    general: [
        "business_legal_entity.type",
        "business_legal_entity.name",
        "business_legal_entity.legal_name",
        "business_legal_entity.tax_identification_number",
        "business_legal_entity.category",
        "business_legal_entity.phone",
        "business_legal_entity.yearly_volume_range",
        "business_legal_entity.average_transaction_amount",
        "business_legal_entity.locale",
        "business_legal_entity.statement_descriptor"
    ],
    businessAddress: ["business_legal_entity.address.street", "business_legal_entity.address.city", "business_legal_entity.address.state", "business_legal_entity.address.zip"],
    businessOwners: [
        "business_legal_entity.principals.0.is_applicant",
        "business_legal_entity.principals.0.is_control_prong",
        "business_legal_entity.principals.0.job_title",
        "business_legal_entity.principals.0.first_name",
        "business_legal_entity.principals.0.last_name",
        "business_legal_entity.principals.0.phone",
        "business_legal_entity.principals.0.ssn",
        "business_legal_entity.principals.0.date_of_birth",
        "business_legal_entity.principals.0.address.street",
        "business_legal_entity.principals.0.address.city",
        "business_legal_entity.principals.0.address.state",
        "business_legal_entity.principals.0.address.zip",
        "business_legal_entity.principals.0.percentage_shareholding",
        "business_legal_entity.is_applicant",
        "business_legal_entity.is_control_prong"
    ],
    bankAccount: ["business_legal_entity.bank_account.account_number", "business_legal_entity.bank_account.routing_number"],
    review: ["accept_terms_and_conditions"]
};

export default {
    components: { EditField },
    props: {
        product_codes: {
            type: Array,
            default() {
                return [];
            }
        },
        terms_and_conditions_links: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            validation_errors: [],
            generalRecord: { currency: "US Dollar", region: "United States" },
            businessAddressRecord: { country: "US" },
            businessOwnersRecord: { country: "US" },
            bankAccountRecord: {},
            accept_terms_and_conditions: false
            // ...demoParams
        };
    },
    computed: {
        generalFields() {
            return GeneralFields;
        },
        addressFields() {
            return AddressFields;
        },
        businessOwnerFields() {
            return BusinessOwnerFields;
        },
        bankAccountFields() {
            return BankAccountFields;
        }
    },
    methods: {
        wait(time) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                }, time);
            });
        },
        async scroll(field, behavior = "smooth") {
            let el = null;
            if (field && typeof field !== "string") {
                el = field;
            } else {
                if (!/[\/\&]/.test(field)) {
                    el = document.querySelector(`#${field}`);
                }
                el = el || document.querySelector(`[name='${field}']`);
            }

            el &&
                el.scrollIntoView({
                    behavior: behavior,
                    block: "center"
                });

            setTimeout(() => {
                el && el.focus();
            }, 200);
        },
        async customValidate() {
            let result = true;
            let showError = async el => {
                el && this.scroll(el);
                this.$store.commit("setMessage", "Please Check Form Validation");
                throw new Error("Validate Error");
            };

            let fields = this.$refs["edit-field"];

            for (let i = 0, l = fields.length; i < l; i++) {
                let field = fields[i];
                let valid = await field.$validator.validate();

                if (!valid) {
                    showError(field.$el);
                    result = false;
                    break;
                }
            }
            return result;
        },
        getRecord() {
            const bor = this.businessOwnersRecord;
            return {
                business_legal_entity: {
                    ...this.generalRecord,
                    region: "US",
                    currency: "usd",
                    average_transaction_amount: this.generalRecord.average_transaction_amount * 100,
                    address: { ...this.businessAddressRecord },
                    principals: [
                        {
                            ssn: bor.ssn,
                            phone: bor.phone,
                            address: {
                                zip: bor.zip,
                                city: bor.city,
                                state: bor.state,
                                street: bor.street,
                                country: bor.country,
                                street2: bor.street2
                            },
                            job_title: bor.job_title,
                            last_name: bor.last_name,
                            first_name: bor.first_name,
                            is_applicant: bor.is_applicant,
                            date_of_birth: bor.date_of_birth,
                            is_control_prong: bor.is_control_prong,
                            percentage_shareholding: bor.percentage_shareholding - 0
                        }
                    ],
                    bank_account: { ...this.bankAccountRecord }
                },
                pricing_templates: this.product_codes,
                accept_terms_and_conditions: this.accept_terms_and_conditions
            };
        },
        async save() {
            const data = await this.$store.dispatch("crud/put", {
                api: "users/vendor/connect",
                data: this.getRecord()
            });
            if (data.validation_errors.length) {
                this.validation_errors = data.validation_errors;
                setTimeout(() => {
                    this.checkValidationErrors();
                }, 10);
            }
            return data;
        },

        checkValidationErrors() {
            let field = "";
            let firstError = this.validation_errors[0];
            let errorField = firstError.split(" ")[0];
            for (let key in fieldsMap) {
                if (fieldsMap[key].indexOf(errorField) > -1) {
                    field = key;
                    break;
                }
            }
            if (field.length) {
                let fields = errorField.split(".");
                let el = this.$refs[field].querySelector(`[data-vv-name="${fields[fields.length - 1]}"]`);
                el && this.scroll(el);
                this.$store.commit("setMessage", firstError);
            }
        },

        initAddressAutoFill() {
            const businessAddressAutoFill = new AddressAutoFill({
                element: this.$refs["businessAddress"],
                addressFieldName: "street",
                updateCityField: city => {
                    this.$set(this.businessAddressRecord, "city", city);
                },
                updateAddressFields: obj => {
                    this.$set(this.businessAddressRecord, "city", obj.city);
                    this.$set(this.businessAddressRecord, "streeet", obj.address);
                    this.$set(this.businessAddressRecord, "state", states[obj.state]);
                    this.$set(this.businessAddressRecord, "zip", obj.zip);
                },
                initCallback: () => {
                    businessOwnersAddressAutoFill.init();
                }
            });

            const businessOwnersAddressAutoFill = new AddressAutoFill({
                element: this.$refs["businessOwners"],
                addressFieldName: "street",
                updateCityField: city => {
                    this.$set(this.businessOwnerFields, "city", city);
                },
                updateAddressFields: obj => {
                    this.$set(this.businessOwnersRecord, "city", obj.city);
                    this.$set(this.businessOwnersRecord, "streeet", obj.address);
                    this.$set(this.businessOwnersRecord, "state", states[obj.state]);
                    this.$set(this.businessOwnersRecord, "zip", obj.zip);
                }
            });

            businessAddressAutoFill.init();
        }
    },
    mounted() {
        this.$nextTick(() => {
            // this.$refs["general-item"].$el.querySelector(".md-button-ghost").click();
            this.initAddressAutoFill();
        });
    }
};
</script>

<style lang="less" scoped>
.md-list-item-expand {
    &.md-active {
        .md-list-expand-container {
            .edit-field:nth-child(1) {
                visibility: visible;
            }
        }
    }

    .md-list-expand-container {
        .edit-field:nth-child(1) {
            visibility: hidden;
        }
    }
}
</style>
