<template>
    <div class="tilled-new-card">
        <md-card>
            <md-card-header v-if="isNew">Please Add Your Card</md-card-header>
            <md-card-header v-if="!isNew">Please Edit Your Card</md-card-header>
            <md-card-content>
                <form @submit.prevent="confirm" action id="payment-form" method="post" role="form">
                    <div v-if="isNew" class="tilled-card" id="tilledCard">
                        <div class="form-group md-input-container md-theme-none">
                            <label for="card-number-element">Card number</label>
                            <div class="inputField" id="card-number-element" autocomplete="cc-number"></div>
                        </div>
                        <div class="row" style="display:flex;">
                            <div class="form-group md-input-container md-theme-none" style="margin-right:10%;">
                                <label><span class="hidden-xs">Expiration</span> </label>
                                <div class="inputField" id="card-expiration-element"></div>
                            </div>
                            <div class="form-group md-input-container md-theme-none">
                                <label>CVV</label>
                                <div class="inputField" id="card-cvv-element"></div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!isNew">
                        <md-input-container>
                            <label for>Card Number</label>
                            <md-input readonly v-model="cardNumber"></md-input>
                        </md-input-container>
                    </div>

                    <div class="name" v-if="needName">
                        <md-input-container>
                            <label for>Card Holder Name</label>
                            <md-input v-model="name"></md-input>
                        </md-input-container>
                    </div>
                    <div class="address" v-if="needAddress" ref="address">
                        <md-input-container>
                            <label for>Address Line1</label>
                            <md-input data-vv-name="address" v-model="address_line1"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>City</label>
                            <md-input data-vv-name="city" v-model="address_city"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>State</label>
                            <md-select v-model="address_state" data-vv-name="state">
                                <md-option :key="ix" :value="state.value" v-for="(state, ix) in stateOptions">{{ state.label }} </md-option>
                            </md-select>
                        </md-input-container>
                        <md-input-container>
                            <label for>Zip</label>
                            <md-input data-vv-name="zip" v-model="address_zip"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>Country</label>
                            <md-select v-model="address_country" data-vv-name="country">
                                <md-option :key="key" :value="country.value" v-for="(country, key) in countryOptions">{{ country.label }} </md-option>
                            </md-select>
                        </md-input-container>
                    </div>
                </form>
            </md-card-content>
            <md-card-actions>
                <md-button @click="cancel">Cancel</md-button>
                <md-button :disabled="errors.any() || loading" @click="confirm" class="md-primary">
                    <md-spinner :md-size="20" class="md-accent spinner" md-indeterminate v-if="!!loading"></md-spinner>
                    Confirm
                </md-button>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { states, country } from "@/config";
import AddressAutoFill from "@/components/Address/AddressAutoFillClass";

export default {
    props: {
        id: { type: String },
        signup: { default: false },
        email: { default: "" },
        needName: { default: true },
        needAddress: { default: true }
    },
    data() {
        return {
            isInit: false,
            tilledPublicKey: "",
            tilledClientId: "",
            tilled: null,
            form: null,
            name: "",
            address_line1: "",
            address_line2: "",
            address_city: "",
            address_state: "",
            address_country: "US",
            address_zip: "",
            card: null, //tilled elements
            loading: false,
            cardNumber: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            env: "env"
        }),

        stateOptions() {
            return Object.keys(states)
                .sort((a, b) => (a > b ? 1 : -1))
                .map(a => {
                    return {
                        label: a,
                        value: states[a]
                    };
                });
        },
        countryOptions() {
            return [{ label: country.name, value: country.code }];
        },

        tilledElementsStyle() {
            return {
                base: {
                    color: "#32325d",
                    lineHeight: "18px",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            };
        },
        isNew() {
            return !this.id || this.id == "new";
        }
    },
    methods: {
        async loadTilledJs() {
            return await require("https://js.tilled.com/v2");
        },
        async getTilledKey() {
            const data = await this.$store.dispatch("crud/get", {
                api: "settings/EzTimePay-public"
            });
            if (data) {
                this.tilledPublicKey = data.tilled_public_key;
                this.tilledClientId = data.tilled_client_id;
            } else {
                this.$store.commit("setMessage", "Can't find the EzTimePay key");
                throw "Can't find the EzTimePay key";
            }
        },
        async setDefault() {
            if (this.signup) {
                return;
            }
            const user = await this.getUser();
            const address = await this.getAddress();
            if (user) {
                this.name = user.name;
            }
            if (address) {
                console.info("default address", address);
                let state = this.stateOptions.find(s => s.label == address.state);
                let country = this.countryOptions.find(c => c.label == address.country);
                this.address_line1 = address.address;
                this.address_line2 = "";
                this.address_city = address.city;
                this.address_state = state && state.value;
                this.address_country = country && country.value;
                this.address_zip = address.zip + "";
            }
        },
        async getAddress() {
            if (this.email) {
                const result = await this.$store.dispatch("crud/get", {
                    api: `addresses`,
                    params: {
                        email: this.email
                    }
                });
                const addresses = result.data;
                return addresses && addresses[0];
            } else {
                const result = await this.$store.dispatch("crud/get", {
                    api: `addresses/my`
                });
                const addresses = result.data;
                return addresses && addresses[0];
            }
        },
        async getUser() {
            if (this.email) {
                const result = await this.$store.dispatch("crud/get", {
                    api: `users/email/${this.email}`
                });
                return result;
            } else {
                return this.user;
            }
        },
        async initTilledElement() {
            if (this.isInit) {
                return;
            }

            await Promise.all([this.loadTilledJs(), this.getTilledKey(), this.setDefault()]);

            // console.log("vic : before window.tilledCardElement", window.tilledCardElement);

            if (window.tilledCardElement) {
                this.$nextTick(() => {
                    const _tilledCardElement = document.getElementById("tilledCard");
                    _tilledCardElement.parentNode.replaceChild(window.tilledCardElement, _tilledCardElement);
                    this.tilled = window.tilledForEz;
                    this.form = window.tilledFormForEz;
                });
                return;
            }

            const tilled = new Tilled(this.tilledPublicKey, this.tilledClientId, {
                sandbox: window.INJECT_ENV ? /dev/.test(window.INJECT_ENV.SERVER_ENV) : true, // just for dev
                log_level: 0
            });

            const form = await tilled.form({
                payment_method_type: "card"
            });

            const fieldOptions = {
                styles: {
                    base: {
                        fontFamily: "Helvetica Neue, Arial, sans-serif",
                        color: "#304166",
                        fontWeight: "400",
                        fontSize: "16px"
                    },
                    invalid: {
                        ":hover": {
                            textDecoration: "underline dotted red"
                        }
                    },
                    valid: {
                        color: "#00BDA5"
                    }
                }
            };

            form.createField("cardNumber", fieldOptions).inject("#card-number-element");
            form.createField("cardExpiry", fieldOptions).inject("#card-expiration-element");
            form.createField("cardCvv", fieldOptions).inject("#card-cvv-element");

            await form.build();

            form.on("validation", event => {
                if (event.field) {
                    event.field.element.classList.remove("success");
                    event.field.element.classList.remove("error");
                    if (event.field.valid) {
                        event.field.element.classList.add("success");
                    } else {
                        event.field.element.classList.add("error");
                    }
                }
            });

            this.isInit = true;
            this.tilled = tilled;
            this.form = form;

            window.tilledCardElement = document.getElementById("tilledCard");

            // console.log("vic : after window.tilledCardElement", window.tilledCardElement);

            window.tilledForEz = tilled;
            window.tilledFormForEz = form;
        },
        initAddressAutoFill() {
            const addressAutoFill = new AddressAutoFill({
                element: this.$refs["address"].$el,
                updateCityField: city => {
                    this.address_city = obj.city;
                },
                updateAddressFields: obj => {
                    this.address_line1 = obj.address;
                    this.address_city = obj.city;
                    this.address_state = obj.state;
                    this.address_zip = obj.zip;
                }
            });
            addressAutoFill.init();
        },
        async confirm() {
            if (this.needName && !this.name) {
                this.$store.commit("setMessage", "Card Holder Name required");
                return;
            }
            if (this.needAddress) {
                if (!this.address_line1 || !this.address_city || !this.address_state || !this.address_country || !this.address_zip) {
                    this.$store.commit("setMessage", "Please Complete Address Fields");
                    return;
                }
            }
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.$store.commit("setLoading", true);

            if (this.isNew) {
                const paymentMethod = await this.createPaymentMethod();

                if (!paymentMethod) {
                    this.loading = false;
                    this.$store.commit("setLoading", false);
                    this.$store.commit("setMessage", "Create PaymentMethod Fail");
                    return;
                }

                if (this.signup) {
                    this.loading = false;
                    this.$store.commit("setLoading", false);
                    return paymentMethod.id;
                }

                const customer = await this.createCustomer();

                await this.attachPaymentMethodToCustomer(paymentMethod.id, customer.id);

                this.$emit("confirm", {
                    // customer,
                    paymentMethod
                });

                await this.$store.dispatch("user/refreshProfile");
                this.$store.commit("setMessage", "Card Saved Successfully");
            } else {
                const result = await this.$store.dispatch("crud/put", {
                    api: `EzTimePay/cards/${this.id}`,
                    data: {
                        billing_details: {
                            address: {
                                country: this.address_country,
                                zip: this.address_zip,
                                state: this.address_state,
                                city: this.address_city,
                                street: this.address_line1
                            }
                        },
                        nick_name: this.name
                    }
                });
                console.info(result);
                this.$emit("confirm", {
                    paymentMethod: result
                });

                this.$store.commit("setMessage", "Card Updated Successfully");
            }

            this.loading = false;
            this.$store.commit("setLoading", false);
        },
        async createPaymentMethod() {
            try {
                const paymentMethod = await this.tilled.createPaymentMethod({
                    form: this.form,
                    type: "card",
                    billing_details: {
                        address: {
                            country: this.address_country,
                            zip: this.address_zip,
                            state: this.address_state,
                            city: this.address_city,
                            street: this.address_line1
                        }
                    },
                    nick_name: this.name
                });

                console.log(`Successful paymentMethod! The paymentMethod id is ${paymentMethod.id}`);
                return paymentMethod;
            } catch (e) {
                console.error(e);
                this.$store.commit("setMessage", "Create PaymentMethod Fail");
            }
        },
        async createCustomer() {
            let { data } = await this.$store.dispatch("crud/post", {
                api: `EzTimePay/customer`,
                data: {
                    email: this.email || this.user.email
                }
            });
            console.info("Stripe Customer Created");
            console.log(`Successful customer! The customer id is ${data.id}`);
            return data;
        },

        async attachPaymentMethodToCustomer(paymentMethodId, customerId) {
            if (!paymentMethodId || !customerId) {
                this.$store.commit("setMessage", "Create Payment Method Error");
                return;
            }
            let result = await this.$store.dispatch("crud/post", {
                api: `EzTimePay/cards/${paymentMethodId}`,
                data: {
                    customerId
                }
            });
            console.info("Stripe Customer Created");
            return result && result.data;
        },
        cancel() {
            this.$emit("cancel");
        },
        async loadCard() {
            const result = await this.$store.dispatch("crud/get", {
                api: `EzTimePay/cards/${this.id}`
            });
            console.info("loadcard", result);
            this.cardNumber = `***************${result.card.last4}`;
            this.name = result.nick_name;
            this.address_line1 = result.billing_details.address.street;

            this.address_city = result.billing_details.address.city;
            this.address_state = result.billing_details.address.state;
            this.address_country = result.billing_details.address.country;
            this.address_zip = result.billing_details.address.zip;
        }
    },
    async mounted() {
        // console.log("vic : tilled new card");
        if (this.isNew) {
            setTimeout(() => {
                this.initTilledElement();
            }, 100);
            this.initAddressAutoFill();
        } else {
            this.loadCard();
        }
    }
};
</script>

<style lang="less">
@import "../../mixins.less";

.tilled-new-card {
    .form-default();

    .md-card {
        margin: 0;
    }

    .md-card-header {
        padding: 0 16px;
    }

    .tilled-elements {
        width: 100%;

        .TilledElement {
            background-color: white;
            height: 40px;
            width: 100%;
            padding: 10px 12px;
            border-radius: 4px;
            border: 1px solid transparent;
            box-shadow: 0 1px 3px 0 #e6ebf1;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
        }

        .TilledElement--focus {
            box-shadow: 0 1px 3px 0 #cfd7df;
        }

        .TilledElement--invalid {
            border-color: #fa755a;
        }

        .TilledElement--webkit-autofill {
            background-color: #fefde5 !important;
        }

        #card-errors {
            color: #fa755a;
            margin-top: 5px;
            padding-left: 24px;
        }
    }

    .tilled-card {
        .inputField {
            height: 32px;
        }
    }
}
</style>
