<template>
    <div class="VendorSignupStripe" :style="{ height: wraperHeight }">
        <div id="container" ref="container"></div>
        <div v-if="showError">Something went wrong!</div>
    </div>
</template>

<script>
import { loadConnect } from "@stripe/connect-js";
export default {
    data() {
        return {
            showError: false,
            stripePublicKey: "",
            timeout: null,
            wraperHeight: "1450px"
        };
    },
    methods: {
        async getStripeKey() {
            const data = await this.$store.dispatch("crud/get", {
                api: "settings/EzTimePay-public"
            });
            if (data && data.stripe_public_key) {
                this.stripePublicKey = data.stripe_public_key;
            } else {
                this.$store.commit("setMessage", "Can't find the stripe key");
                throw "Can't find the stripe key";
            }
        },
        async initConnectComponent() {
            await this.getStripeKey();
            const fetchClientSecret = async () => {
                // Fetch the AccountSession client secret
                const { client_secret } = await this.$store.dispatch("crud/post", {
                    api: "users/vendor/stripe-session"
                });
                if (!client_secret) {
                    this.showError = true;
                    return undefined;
                } else {
                    this.showError = false;
                    return client_secret;
                }
            };
            const clientSecret = await fetchClientSecret();
            const stripeConnect = await loadConnect();
            if (clientSecret) {
                const connectInstance = stripeConnect.initialize({
                    // This is your test publishable API key.
                    publishableKey: this.stripePublicKey,
                    clientSecret,
                    // Returns a new client secret on long running sessions
                    // when the initially provided client secret might expire.
                    refreshClientSecret: fetchClientSecret,
                    appearance: {
                        overlays: "drawer"
                    },
                    locale: "en-US"
                });
                const onboardingComponent = connectInstance.create("account-onboarding");
                onboardingComponent.addEventListener("exit", e => {
                    console.log("User exited the onboarding flow");
                    this.$emit("stripe-input-exist");
                });
                const container = document.getElementById("container");
                container.appendChild(onboardingComponent);
            }
        },
        resetContainerHeight() {
            // const container = document.querySelector(".md-steps-container");
            // const height = document.documentElement.clientHeight - container.getBoundingClientRect().top + "px";
            // this.wraperHeight = height;
        }
    },
    mounted() {
        // this.resetContainerHeight();
        this.initConnectComponent();
    }
};
</script>

<style lang="less" scoped>
.VendorSignupStripe {
    overflow-y: auto;
}
</style>
