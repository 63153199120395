<template>
    <div>
        <VendorSignupForStripe v-if="ready && !useTilled" />
        <VendorSignupForTilled v-if="ready && useTilled" />
    </div>
</template>

<script>
import VendorSignupForStripe from "@/components/Vendor/Stripe/VendorSignupForStripe";
import VendorSignupForTilled from "@/components/Vendor/Tilled/VendorSignupForTilled";
export default {
    components: {
        VendorSignupForStripe,
        VendorSignupForTilled
    },
    data() {
        return {
            ready: false,
            useTilled: false
        };
    },
    async created() {
        this.$store.commit("user/setUser", null);
        const data = await this.$store.dispatch("crud/get", {
            api: "settings/EzTimePay-public"
        });
        this.ready = true;
        this.useTilled = data && data.use_tilled;
    }
};
</script>

<style lang="less" scoped></style>
