<template>
    <div class="tilled-card-edit">
        <tilled-new-card ref="tilledNewCard" @confirm="confirm" @cancel="cancel" :signup="true"></tilled-new-card>
    </div>
</template>
<script>
import TilledNewCard from "@/components/Tilled/TilledNewCard";
export default {
    components: {
        TilledNewCard
    },
    methods: {
        async getPaymentMethodId() {
            const paymentMethodId = await this.$refs["tilledNewCard"].confirm();
            return paymentMethodId;
        },
        confirm() {},
        cancel() {}
    }
};
</script>
<style lang="less" scoped>
.tilled-card-edit {
    /deep/ .tilled-new-card {
        max-width: 1000px;
        margin: auto;

        .md-card-header {
            display: none;
        }
    }
    /deep/ .md-card .md-card-actions {
        display: none;
    }
}
</style>
